























































































































































import { IInfringement, IInfringementResponse } from '@/interfaces/infringement';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    data() {
        return {

            active: false,
            infringement: {} as IInfringement,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IInfringementResponse>('infringements', {
                title: this.infringement.title,
                description: this.infringement.description,
                priority: this.infringement.priority,
                client_id: this.infringement.client ? this.infringement.client.id : null,
                object_id: this.infringement.object ? this.infringement.object.id : null,
            }).then(({ data }) => {
                this.$router.push({ name: 'infringements.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
